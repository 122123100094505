import React, { useState } from 'react';
import './Admin.css';  // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBlog, faSignOutAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import Dashboard from './Dashboard/Dashboard';
import Blog from './Blog/Blog';
import Users from './Users/Users';

const Admin = () => {
  const [activeSection, setActiveSection] = useState("home");

  const renderContent = () => {
    switch (activeSection) {
      case "home":
        return (
          <Dashboard />
        );
        case "blog":
          return (
            <Blog />
          );
        case "users":
          return (
            <Users />
          );
      case "logout":
        return (
          <section className="about-section">
            <div className="content">
              <h2>Cerrar Sesión</h2>
              <p className="lead">
                ¿Estás seguro de que deseas cerrar sesión? Asegúrate de guardar todos tus cambios antes de continuar.
              </p>
            </div>
            {/* <div className="icon-container">
              <FontAwesomeIcon icon={faSignOutAlt} size="4x" />
            </div> */}
          </section>
        );
      default:
        return null;
    }
  };

  return (
    <main className="admin-page bg-gray-50 min-h-screen py-12 px-4">
      <div className="max-w-7xl mx-auto px-4 mb-16 text-center">
        <h1 className="text-5xl font-bold-800 mb-6">Panel de Administración</h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Administra tu contenido y configuraciones desde aquí.
        </p>
      </div>

      {/* Navigation Menu */}
      <nav className="admin-nav">
  <ul className="flex justify-center space-x-4">
    <li>
      <a
        href="#home"
        onClick={(e) => {
          e.preventDefault();
          setActiveSection("home");
        }}
        className={`nav-link button ${activeSection === "home" ? "active" : ""}`}
      >
        <FontAwesomeIcon icon={faHome} /> &nbsp;  Dashboard
      </a>
    </li>
    <li>
      <a
        href="#blog"
        onClick={(e) => {
          e.preventDefault();
          setActiveSection("blog");
        }}
        className={`nav-link button ${activeSection === "blog" ? "active" : ""}`}
      >
        <FontAwesomeIcon icon={faBlog} /> &nbsp; Blog
      </a>
    </li>
    <li>
      <a
        href="#users"
        onClick={(e) => {
          e.preventDefault();
          setActiveSection("users");
        }}
        className={`nav-link button ${activeSection === "users" ? "active" : ""}`}
      >
        <FontAwesomeIcon icon={faUsers} /> Users
      </a>
    </li>
    <li>
      <a
        href="#logout"
        onClick={(e) => {
          e.preventDefault();
          setActiveSection("logout");
        }}
        className={`nav-link button ${activeSection === "logout" ? "active" : ""}`}
      >
        <FontAwesomeIcon icon={faSignOutAlt} /> &nbsp;  Logout
      </a>
    </li>
  </ul>
</nav>

      {/* Main Content */}
      <main style={{ flex: 1, padding: "20px" }}>
        {renderContent()}
      </main>
    </main>
  );
};

export default Admin;