import React, { useEffect, useState } from 'react';
import './Users.css';  // Import the CSS file
import dynamoDb from '../../../connections/aws-config';
import Modal from "react-modal"; // Optional: If using a library

Modal.setAppElement("#root");

function Users() {

  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // For editing user
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    password: "",
    user_type: "",
    active: true,
  });
  const [validationError, setValidationError] = useState("");

  // Fetch users from DynamoDB
  const fetchUsers = async () => {
    const params = {
      TableName: "smart-mark-users",
      FilterExpression: "active = :active",
      ExpressionAttributeValues: {
        ":active": true,
      },
    };
  
    try {
      const data = await dynamoDb.scan(params).promise();
      const users = data.Items.map((item) => ({
        id: item.id,
        username: item.username,
        email: item.email,
        password: item.password,
        user_type: item.user_type,
        active: item.active,
      }));
      setUsers(users);
    } catch (error) {
      console.error("Error fetching users from DynamoDB:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const params = {
      TableName: "smart-mark-users",
      FilterExpression: "#email = :email AND #user_type = :user_type",
      ExpressionAttributeNames: {
        "#email": "email",
        "#user_type": "user_type",
      },
      ExpressionAttributeValues: {
        ":email": newUser.email,
        ":user_type": newUser.user_type,
      },
    };
  
    try {
      const data = await dynamoDb.scan(params).promise();
  
      if (data.Items.length > 0) {
        setValidationError("Usuario ya se encuentra en el sistema");
        return;
      }
  
      const newUserParams = {
        TableName: "smart-mark-users",
        Item: {
          id: Date.now().toString(),
          username: newUser.username,
          email: newUser.email,
          password: newUser.password,
          user_type: newUser.user_type,
          active: newUser.active,
        },
      };
  
      await dynamoDb.put(newUserParams).promise();
  
      setUsers((prevUsers) => [
        ...prevUsers,
        {
          id: newUserParams.Item.id,
          username: newUserParams.Item.username,
          email: newUserParams.Item.email,
          password: newUserParams.Item.password,
          user_type: newUserParams.Item.user_type,
          active: newUserParams.Item.active,
        },
      ]);
  
      setIsModalOpen(false);
      setNewUser({
        username: "",
        email: "",
        password: "",
        user_type: "",
        active: true,
      });
      setValidationError(""); // Clear any previous errors
    } catch (error) {
      console.error("Error checking or creating user in DynamoDB:", error);
    }
  };

  const handleDelete = async (id) => {
    const params = {
      TableName: "smart-mark-users",
      Key: {
        id: id, // Unique ID of the user
      },
      UpdateExpression: "SET active = :active",
      ExpressionAttributeValues: {
        ":active": false,
      },
    };
  
    try {
      await dynamoDb.update(params).promise();
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== id) // Remove from active display
      );
    } catch (error) {
      console.error("Error setting user to inactive:", error);
      alert("Error al desactivar el usuario. Por favor, inténtelo de nuevo.");
    }
  };

  const openEditModal = (user) => {
    setCurrentUser(user); // Set the selected user for editing
    setIsEditModalOpen(true); // Open the edit modal
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
  
    const params = {
      TableName: "smart-mark-users",
      Key: {
        id: currentUser.id,
      },
      UpdateExpression:
        "SET username = :username, email = :email, password = :password, user_type = :user_type, active = :active",
      ExpressionAttributeValues: {
        ":username": currentUser.username,
        ":email": currentUser.email,
        ":password": currentUser.password,
        ":user_type": currentUser.user_type,
        ":active": currentUser.active,
      },
    };
  
    try {
      // Update user in DynamoDB
      await dynamoDb.update(params).promise();
  
      // Refresh the list of users by fetching from DynamoDB
      await fetchUsers();
  
      // Close the edit modal
      setIsEditModalOpen(false);
  
      // Clear the current user state
      setCurrentUser(null);
    } catch (error) {
      console.error("Error updating user in DynamoDB:", error);
      alert("Error al actualizar el usuario. Por favor, inténtelo de nuevo.");
    }
  };

  return (
    <section className="users-management">
      <div className="users-header">
        <h2 className="text-2xl font-bold mb-4">Gestión de Usuarios</h2>
        <button className="add-user-btn" onClick={toggleModal}>
          Agregar Nuevo Usuario
        </button>
      </div>

      <div className="users-grid">
        {users.map((user) => (
          <div key={user.id} className="user-card">
            <div className="user-info">
              <h3 className="user-name">{user.username}</h3>
              <p className="user-email">{user.email}</p>
              <span className="user-role">{user.user_type}</span>
            </div>
            <div className="user-actions">
              <button
                className="edit-btn"
                onClick={() => openEditModal(user)}
              >
                Editar
              </button>
              <button
                className="delete-btn"
                onClick={() => handleDelete(user.id)}
              >
                Eliminar
              </button>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={toggleModal}
          contentLabel="Agregar Nuevo Usuario"
          className="modal"
          overlayClassName="overlay"
        >
          <h2>Agregar Nuevo Usuario</h2>
          {validationError && <p className="error-message">{validationError}</p>}
          <form onSubmit={handleSubmit}>
            <label>
              Nombre de Usuario:
              <input
                type="text"
                name="username"
                value={newUser.username}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={newUser.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Contraseña:
              <input
                type="password"
                name="password"
                value={newUser.password}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Tipo de Usuario:
              <select
                name="user_type"
                value={newUser.user_type}
                onChange={handleChange}
                required
              >
                <option value="">Seleccionar</option>
                <option value="admin">Admin</option>
                <option value="blogger">Blogger</option>
                <option value="cliente">Cliente</option>
              </select>
            </label>
            <button type="submit" className="submit-btn">
              Crear Usuario
            </button>
          </form>
          <button onClick={toggleModal} className="close-btn">
            Cerrar
          </button>
        </Modal>
      )}

      {isEditModalOpen && (
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={() => setIsEditModalOpen(false)}
          contentLabel="Editar Usuario"
          className="modal"
          overlayClassName="overlay"
        >
          <h2>Editar Usuario</h2>
          <form onSubmit={handleEditSubmit}>
            <label>
              Nombre de Usuario:
              <input
                type="text"
                name="username"
                value={currentUser.username}
                onChange={(e) =>
                  setCurrentUser({ ...currentUser, username: e.target.value })
                }
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={currentUser.email}
                onChange={(e) =>
                  setCurrentUser({ ...currentUser, email: e.target.value })
                }
                required
              />
            </label>
            <label>
              Contraseña:
              <input
                type="password"
                name="password"
                value={currentUser.password}
                onChange={(e) =>
                  setCurrentUser({ ...currentUser, password: e.target.value })
                }
                required
              />
            </label>
            <label>
              Tipo de Usuario:
              <select
                name="user_type"
                value={currentUser.user_type}
                onChange={(e) =>
                  setCurrentUser({ ...currentUser, user_type: e.target.value })
                }
                required
              >
                <option value="">Seleccionar</option>
                <option value="admin">Admin</option>
                <option value="blogger">Blogger</option>
                <option value="cliente">Cliente</option>
              </select>
            </label>
            <label>
              Activo:
              <select
                name="active"
                value={currentUser.active ? "true" : "false"}
                onChange={(e) =>
                  setCurrentUser({
                    ...currentUser,
                    active: e.target.value === "true",
                  })
                }
              >
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </label>
            <button type="submit" className="submit-btn">
              Guardar Cambios
            </button>
          </form>
          <button
            onClick={() => setIsEditModalOpen(false)}
            className="close-btn"
          >
            Cerrar
          </button>
        </Modal>
      )}
    </section>
  );
}

export default Users;
