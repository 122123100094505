import './HomePage.css';  // Import the CSS file
import CallToAction from '../../components/CallToAction/CallToAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faCode, faShareNodes } from '@fortawesome/free-solid-svg-icons';

function HomePage() {
    return (
        <div className="homePage">
        <section className="hero" id="inicio">
          <h2>El poder de la estrategia en tus manos</h2>
          <p>Empoderamos tu negocio con estrategias de marketing personalizadas que puedes implementar de manera independiente. Transforma tus ideas en acciones efectivas y lleva tu marca al siguiente nivel, sin depender de nadie más.</p>
        </section>

        <section id="servicios" className="services">
          <h2>Nuestros Servicios</h2>
          <div className="services-grid">
            
            <div className="service-card">
              <div>
                <h3 id="OnPointPromos">OnPoint Promos <FontAwesomeIcon icon={faRocket} /></h3>
                <p>Potencia tus ventas en el punto de venta con promociones estratégicas que atraen, conectan y convierten</p>
              </div>
            </div>

            <div className="service-card">
              <div>
                <h3 id="WebImpact">Web Impact <FontAwesomeIcon icon={faCode} /></h3>
                <p>Maximiza tu presencia digital con estrategias web personalizadas que impulsan el crecimiento y el posicionamiento de tu marca en línea</p>
              </div>
              </div>

            <div className="service-card">
              <div>
                <h3 id="SocialBoost">Social Boost <FontAwesomeIcon icon={faShareNodes} /></h3>
                <p>Aumenta tu visibilidad en redes sociales con contenido y estrategias que conectan con tu audiencia y generan engagement</p>
              </div>
            </div>
          </div>
        </section>

        <section id="nosotros" className="about">
          <h2>Sobre Nosotros</h2>
          <p>En SmartMark Consultores, diseñamos estrategias de marketing personalizadas que te brindan el control total. Nos especializamos en crear planes de marketing hechos a la medida, alineados con las necesidades específicas de tu negocio, para que puedas implementarlos de manera independiente y alcanzar resultados reales.</p>

          <p>Sabemos que cada empresa es única. Por eso, trabajamos contigo para entender a fondo tus objetivos y diseñar estrategias de marketing efectivas y prácticas, listas para ser aplicadas sin ayuda externa. Nuestro compromiso es empoderarte, brindándote herramientas de marketing claras y accesibles para que lleves tu negocio al siguiente nivel de forma autónoma.</p>

          <p>Descubre cómo podemos ayudarte a transformar tus ideas en acciones estratégicas que generen resultados. SmartMark Consultores: estrategias hechas para que tú mismo las pongas en marcha y logres el éxito que deseas.</p>
        </section>

        
        <CallToAction />


        </div>
        );
    }
    
    export default HomePage;