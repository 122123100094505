import './Blog.css';  // Import the CSS file

function Blog() {
    return (
        <section className="blog-management">
              <div className="blog-header">
                <h2 className="text-2xl font-bold mb-4">Gestión de Blog</h2>
                <button className="add-blog-btn">
                  Crear Nueva Publicación
                </button>
              </div>
              
              <div className="blog-grid">
                {[
                  { 
                    id: 1, 
                    title: "Introducción al Marketing Digital", 
                    author: "Juan Pérez", 
                    date: "2023-06-15", 
                    status: "Publicado" 
                  },
                  { 
                    id: 2, 
                    title: "Estrategias de SEO en 2023", 
                    author: "María García", 
                    date: "2023-06-10", 
                    status: "Borrador" 
                  },
                  { 
                    id: 3, 
                    title: "Tendencias de Diseño Web", 
                    author: "Carlos Rodríguez", 
                    date: "2023-05-25", 
                    status: "Publicado" 
                  },
                  { 
                    id: 4, 
                    title: "Guía Completa de Social Media", 
                    author: "Ana Martínez", 
                    date: "2023-06-05", 
                    status: "Pendiente" 
                  },
                ].map((post) => (
                  <div key={post.id} className="blog-card">
                    <div className="blog-info">
                      <h3 className="blog-title">{post.title}</h3>
                      <div className="blog-meta">
                        <span className="blog-author">Autor: {post.author}</span>
                        <span className="blog-date">Fecha: {post.date}</span>
                      </div>
                      <span className={`blog-status ${post.status.toLowerCase().replace(' ', '-')}`}>
                        {post.status}
                      </span>
                    </div>
                    <div className="blog-actions">
                      <button className="view-btn">Ver</button>
                      <button className="edit-btn">Editar</button>
                      <button className="delete-btn">Eliminar</button>
                    </div>
                  </div>
                ))}
              </div>
            </section>
        );
    }
    
    export default Blog;