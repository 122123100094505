import './App.css';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
  

  return (
    <div>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Footer />
      </div>
    </div>
  );
}

export default App;
