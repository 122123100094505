import './Servicios.css';  // Import the CSS file
import CallToAction from '../../components/CallToAction/CallToAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faCode, faShareNodes } from '@fortawesome/free-solid-svg-icons';

function Servicios() {
    
    return (
      <div className="servicios-page bg-gray-50 min-h-screen py-12 px-4">
        {/* Hero Section */}
        <div className="max-w-7xl mx-auto px-4 mb-16 text-center">
          <h1 className="text-5xl font-bold-800 mb-6">
            Nuestros Servicios
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Soluciones estratégicas diseñadas para impulsar tu negocio al siguiente nivel. 
            Combinamos creatividad, tecnología y estrategia para entregar resultados excepcionales.
          </p>
        </div>
  
        {/* Services Container */}
        <div className="max-w-7xl mx-auto px-4 space-y-16">
          {/* OnPoint Promos */}
          <section id="onpoint-promos" className="service-section bg-white rounded-2xl shadow-lg overflow-hidden">
            <div className="service-content p-8 md:p-12">
              <div className="service-header flex items-center gap-4 mb-8">
                <i className="fi fi-rr-target text-4xl text-violet-600"></i>
                <h2 className="text-3xl font-bold text-gray-800" id="OnPointPromos">OnPoint Promos</h2>
              </div>
              
              <p className="text-xl text-gray-600 mb-8">
              Promociones efectivas en el punto de venta que atraen y convierten
              </p>

              <p>
              OnPoint Promos está diseñado para captar la atención de los consumidores en el momento de compra y aumentar la conversión en el punto de venta. Creamos campañas y promociones estratégicas que destacan en el entorno comercial, desde ofertas hasta activaciones en tiendas. Nos aseguramos de que cada promoción esté alineada con los intereses del cliente y el posicionamiento de tu marca, logrando una conexión efectiva en el momento justo.
              </p>
              
              <div className="grid md:grid-cols-2 gap-8">
                <div className="service-features">
                  <h3 className="flex items-center gap-2 text-xl font-semibold mb-4 text-gray-800">
                    <i className="fi fi-rr-list-check text-violet-600"></i>
                    Beneficios de OnPoint Promos:
                  </h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start gap-2">
                      
                      Incremento de ventas en el punto de venta mediante promociones estratégicas
                      </li>
                    <li className="flex items-start gap-2">
                      
                      Diseño de activaciones y campañas atractivas
                      </li>
                    <li className="flex items-start gap-2">
                      
                      Optimización de material promocional y mensajes clave
                      </li>
                    <li className="flex items-start gap-2">
                      
                      Estrategias que conectan directamente con los consumidores en el lugar de compra
                      </li>
                    
                  </ul>
                </div>
  
                
              </div>

              <p>
              Con OnPoint Promos, transforma cada punto de venta en una oportunidad para fidelizar y ganar clientes.
              <FontAwesomeIcon icon={faRocket} />
              </p>
            </div>
          </section>
  
          {/* Web Impact */}
          <section id="web-impact" className="service-section bg-white rounded-2xl shadow-lg overflow-hidden">
            <div className="service-content p-8 md:p-12">
              <div className="service-header flex items-center gap-4 mb-8">
                <i className="fi fi-rr-browser text-4xl text-violet-600"></i>
                <h2 className="text-3xl font-bold text-gray-800" id="WebImpact">Web Impact</h2>
              </div>
              
              <p className="text-xl text-gray-600 mb-8">
                Maximiza tu presencia digital con estrategias web personalizadas 
                que impulsan el crecimiento y posicionamiento de tu marca.
              </p>

              <p>
              En <strong>Web Impact</strong>, diseñamos estrategias de marketing digital que llevan tu negocio al siguiente nivel. Desde el análisis profundo de tu audiencia hasta la optimización de contenido y SEO, nuestro enfoque garantiza que tu marca se destaque en un entorno digital competitivo. Nos enfocamos en mejorar la visibilidad de tu sitio web, incrementar el tráfico orgánico y generar conversiones efectivas, adaptando cada estrategia a las necesidades y objetivos únicos de tu empresa.
              </p>
              
              <div className="grid md:grid-cols-2 gap-8">
                <div className="service-features">
                  <h3 className="flex items-center gap-2 text-xl font-semibold mb-4 text-gray-800">
                    <i className="fi fi-rr-list-check text-violet-600"></i>
                    Beneficios de Web Impact:
                  </h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start gap-2">
                      
                      Aumento del posicionamiento en motores de búsqueda (SEO)
                      </li>
                    <li className="flex items-start gap-2">
                      
                      Optimización de la experiencia de usuario en la web
                      </li>
                    <li className="flex items-start gap-2">
                      
                      Creación de contenido relevante y atractivo
                      </li>
                    <li className="flex items-start gap-2">
                      
                      Estrategias de conversión para maximizar los resultados
                      </li>
                    
                  </ul>
                </div>

                
  
                
              </div>
              <p>
                Con Web Impact, logra una presencia online sólida y sostenible que potencie tu negocio.
                <FontAwesomeIcon icon={faCode} />
                </p>
            </div>
          </section>
  
          {/* Social Boost */}
          <section id="social-boost" className="service-section bg-white rounded-2xl shadow-lg overflow-hidden">
            <div className="service-content p-8 md:p-12">
              <div className="service-header flex items-center gap-4 mb-8">
                <i className="fi fi-rr-megaphone text-4xl text-violet-600"></i>
                <h2 className="text-3xl font-bold text-gray-800" id="SocialBoost">Social Boost</h2>
              </div>
              
              <p className="text-xl text-gray-600 mb-8">
              Estrategias de redes sociales que conectan y generan impacto
              </p>

              <p>
              Social Boost te ayuda a conectar con tu audiencia en redes sociales a través de contenido relevante y estrategias optimizadas. Desde la creación de campañas hasta el análisis de métricas, nos aseguramos de que tu marca esté siempre en el lugar correcto y en el momento adecuado. Aumentamos tu visibilidad y engagement en plataformas clave como Instagram, Facebook, LinkedIn y Twitter, alineando cada estrategia a los intereses y comportamientos de tus seguidores.
              </p>
              
              <div className="grid md:grid-cols-2 gap-8">
                <div className="service-features">
                  <h3 className="flex items-center gap-2 text-xl font-semibold mb-4 text-gray-800">
                    <i className="fi fi-rr-list-check text-violet-600"></i>
                    Beneficios de Social Boost:
                  </h3>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start gap-2">
                      
                      Mayor engagement con publicaciones que inspiran interacción
                      </li>
                    <li className="flex items-start gap-2">
                      
                      Creación de contenido alineado con tu identidad de marca
                      </li>
                    <li className="flex items-start gap-2">
                      
                      Optimización de anuncios para llegar a tu audiencia ideal
                      </li>
                    <li className="flex items-start gap-2">
                      
                      Análisis de métricas para ajustes estratégicos y crecimiento constante
                      </li>
                    
                  </ul>
                </div>

                <p>
                Con Social Boost, transforma tus redes en poderosas herramientas de marketing y fidelización de clientes.
                <FontAwesomeIcon icon={faShareNodes} />
                </p>
                
              </div>
            </div>
          </section>

          
          <CallToAction />

          
        </div>
      </div>
    );
  }
  
  export default Servicios;