import './CallToAction.css';  // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faCode, faShareNodes } from '@fortawesome/free-solid-svg-icons';

function CallToAction() {
    
    return (
        <div className="cta-banner">
            <h2>¿Listo para impulsar tu negocio?</h2>
            <p>
            Descubre cómo nuestras soluciones personalizadas pueden ayudarte a alcanzar tus objetivos. 
            Contáctanos hoy para una consulta gratuita.
            </p>
            <div className="cta-button-container">
            <button 
                className="cta-primary-button"
                onClick={() => window.location.href = '/contacto'}
            >
                Contactar Ahora
            </button>
            </div>
        </div>
    );
  }
  
  export default CallToAction;