import './Footer.css';  // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
        <footer className="footer" role="contentinfo">
            <div className="footer-content">
                <div className="social-links">
                    <a href="https://www.instagram.com/yourusername" className="contact-item" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="2x" className="icon-primary" />
                    </a>
                    &nbsp;
                    <a href="https://www.linkedin.com/yourusername" className="contact-item" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" className="icon-primary" />
                    </a>
                    &nbsp;
                    <a href="https://www.facebook.com/yourusername" className="contact-item" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="2x" className="icon-primary" />
                    </a>
                </div>
                <p>&copy; {new Date().getFullYear()} SmartMark Consultores. All rights reserved.</p>
            </div>
        </footer>
 );
}

export default Footer;