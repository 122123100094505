import './Blog.css';  // Import the CSS file
import CallToAction from '../../components/CallToAction/CallToAction';

function BlogPage() {
    return (
        <div className="blog-page bg-gray-50 min-h-screen py-12 px-4">
        <div className="max-w-7xl mx-auto px-4 mb-16 text-center">
          <h1 className="text-5xl font-bold-800 mb-6">
          Nuestro Blog
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Explora nuestras últimas publicaciones y descubre estrategias de marketing para tu negocio.
          </p>
        </div>

            {/* Articles Section */}
            <section className="articles-section">
                <div className="about-section left-icon">
                    
                    <div className="content">
                        <h2>Título del Artículo 1</h2>
                        <p>Resumen del artículo 1. Aprende sobre estrategias efectivas para tu negocio.</p>
                    </div>
                </div>

                <div className="about-section right-icon">
                    <div className="content">
                        <h2>Título del Artículo 2</h2>
                        <p>Resumen del artículo 2. Descubre cómo maximizar tu presencia digital.</p>
                    </div>
                    
                </div>

                <div className="about-section left-icon">
                    <div className="content">
                        <h2>Título del Artículo 3</h2>
                        <p>Resumen del artículo 3. Aumenta tu visibilidad en redes sociales.</p>
                    </div>
                </div>
            </section>

            {/* Call to Action */}
            <CallToAction />
        </div>
    );
}

export default BlogPage;