import './Dashboard.css';  // Import the CSS file

function Dashboard() {
    return (
        <section className="dashboard-grid">
            <div className="dashboard-box">
                <h3 className="dashboard-box-title">Total Users</h3>
                <div className="dashboard-box-number">42</div>
                <p className="dashboard-box-subtitle">Active Users</p>
            </div>
            <div className="dashboard-box">
                <h3 className="dashboard-box-title">Blog Posts</h3>
                <div className="dashboard-box-number">23</div>
                <p className="dashboard-box-subtitle">Published Articles</p>
            </div>
            <div className="dashboard-box">
                <h3 className="dashboard-box-title">Page Views</h3>
                <div className="dashboard-box-number">1,256</div>
                <p className="dashboard-box-subtitle">Last 30 Days</p>
            </div>
            {/* <div className="dashboard-box">
                <h3 className="dashboard-box-title">Comments</h3>
                <div className="dashboard-box-number">87</div>
                <p className="dashboard-box-subtitle">Pending Review</p>
            </div> */}
        </section>
        );
    }
    
    export default Dashboard;