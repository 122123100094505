import './Nosotros.css';  // Import the CSS file
import CallToAction from '../../components/CallToAction/CallToAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faQuestionCircle, faCogs, faBullseye, faClock } from '@fortawesome/free-solid-svg-icons';

function Nosotros() {
    return (
      <main className="about-page bg-gray-50 min-h-screen py-12 px-4">

        <div className="max-w-7xl mx-auto px-4 mb-16 text-center">
            <h1 className="text-5xl font-bold-800 mb-6">
                Sobre Nosotros
            </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Soluciones estratégicas diseñadas para impulsar tu negocio al siguiente nivel. 
            Combinamos creatividad, tecnología y estrategia para entregar resultados excepcionales.
          </p>
        </div>

        {/* Por Que Section */}
        <section className="about-section right-icon">
          <div className="content">
            <h2>¿Por qué lo hacemos?</h2>
            <i>La razón detrás de lo que hacemos</i>
            <p className="lead">
            En SmartMark Consultores, creemos que el marketing debe empoderar a los negocios. 
            Nuestro propósito es proporcionar estrategias claras, prácticas y efectivas que permitan a nuestros clientes tomar el control de su crecimiento. 
            Nos apasiona ayudar a las marcas a alcanzar su máximo potencial, ofreciéndoles herramientas que puedan implementar de manera autónoma y sostenible.
            </p>
            <p className="lead">
            Además, entendemos que un plan de marketing no tiene que ser caro para ser efectivo. 
            Nos preocupamos por el presupuesto de nuestros clientes y trabajamos para garantizar que cada estrategia sea accesible y rentable, 
            desafiando la idea de que el buen marketing siempre implica grandes inversiones.
            </p>
          </div>
          <div className="icon-container">
          <FontAwesomeIcon icon={faBullseye} />
          </div>
        </section>
  
        {/* Como Section */}
        <section className="about-section left-icon">
          <div className="icon-container">
          <FontAwesomeIcon icon={faCogs} />
          </div>
          <div className="content">
            <h2>¿Cómo lo hacemos?</h2>
            <i>El proceso marca la diferencia</i>
            <p className="lead">
            Trabajamos contigo desde el principio, entendiendo tus necesidades, objetivos y desafíos únicos. 
            Nuestro enfoque es colaborativo y estratégico: analizamos tu situación actual, identificamos oportunidades y diseñamos un plan a medida. 
            Todo lo que hacemos está enfocado en darte soluciones prácticas que sean fáciles de aplicar, asegurando resultados visibles sin complicaciones. 
            Nuestro éxito se mide en tu independencia.

            </p>
          </div>
        </section>
  
        {/* Que Section */}
        {/* <section className="about-section right-icon">
          <div className="content">
            <h2>Nuestro Qué</h2>
            <p className="lead">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor 
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
              nostrud exercitation ullamco laboris.
            </p>
          </div>
          <div className="icon-container">
          <FontAwesomeIcon icon={faBullseye} />
          </div>
        </section> */}
  
        {/* Cuando Section */}
        {/* <section className="about-section left-icon">
          <div className="icon-container">
          <FontAwesomeIcon icon={faClock} />
          </div>
          <div className="content">
            <h2>Nuestro Cuándo</h2>
            <p className="lead">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor 
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
              nostrud exercitation ullamco laboris.
            </p>
          </div>
        </section> */}
  
        {/* Call to Action */}
        <CallToAction />

      </main>
    );
  }
  
  export default Nosotros;