import './Login.css';  // Import the CSS file
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dynamoDb from '../../connections/aws-config';

const Login = ({ toggleLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Function to check credentials in DynamoDB
  const checkCredentials = async (email, password) => {
    const params = {
      TableName: 'smart-mark-users',
      FilterExpression: 'email = :email AND password = :password',
      ExpressionAttributeValues: {
        ':email': email,
        ':password': password,
      },
    };

    try {
      const data = await dynamoDb.scan(params).promise();
      if (data.Items.length > 0) {
        return true; // Credentials are valid
      }
      return false; // Credentials are invalid
    } catch (err) {
      console.error('Error querying DynamoDB:', err);
      throw new Error('Error querying DynamoDB');
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const isValid = await checkCredentials(email, password);
      if (isValid) {
        localStorage.setItem('logged_user', JSON.stringify(true)); // Save login status to local storage
        toggleLogin(true); // Notify parent component about login status
        navigate('/admin'); // Redirect to Admin page
      } else {
        setError('Invalid email or password'); // Show error message
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      console.error(err);
    }
  };

  return (
    <div className="login-container">
      <h1 className="login-title">Login</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="login-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className="login-input"
        />
        <button type="submit" className="login-button">Login</button>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
};

export default Login;
