import AWS from 'aws-sdk';

// Configure AWS SDK
AWS.config.update({
    region: 'us-east-2', // Replace with your AWS region
    accessKeyId: 'AKIATCZAMZ4M4RQQTAZQ', // Replace with your IAM access key ID
    secretAccessKey: 'CTWbBMdzUI6L7ZpLtfrHM7EkdtWYQp+5GGb4WjF5', // Replace with your IAM secret access key
});

const dynamoDb = new AWS.DynamoDB.DocumentClient();

export default dynamoDb;
